import React from 'react'
import styled from "@emotion/styled";
import { H2, H5, Paragraph1, Paragraph2 } from "@styles/Global.styles";
import { GridColumn, GridRow } from "semantic-ui-react";
import mq from '@styles/mq'

export const ImageGridItemImage = styled.img`
    border-radius: 4px;
    width: 100%;
`

export const ImageGridItemHeading = styled(({color, ...others})=><H5 {...others}></H5>)`
    margin-top: 10px;
    margin-bottom: 0px;
    color: ${props => props.color};
`

export const ImageGridItemSubHeading = styled(({color, ...others})=><Paragraph2 {...others}></Paragraph2>)`
    ${mq({
        marginBottom: ['20px !important','20px !important','30px !important']
    })}
    color: ${props => props.color};
`

export const ImageGridHeading = styled(({color, ...others})=><H2 {...others}></H2>)`
    color: ${props => props.color};
`

export const ImageGridSubHeading = styled(({color, ...others})=><Paragraph1 {...others}></Paragraph1>)`
    margin-bottom: 8px !important;
    color: ${props => props.color};
`

export const ImageGridHeadingContainer = styled.div`
    text-align: center;
    width: 100%;
    ${mq({
        marginBottom: ['0','0','10px']
    })}
`

export const ImageGridColumn = styled(GridColumn)`
    ${mq({
        maxWidth: ['auto','1000px','430px']
    })}
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
`
import React from "react"
import { ImageGridItemProps } from "@models/ImageGrid/ImageGrid.models"
import { ImageGridItemHeading, ImageGridItemSubHeading, ImageGridItemImage } from "./ImageGrid.styles"
import { Link } from "gatsby"
import theme from "@styles/theme"

const renderImageGridItemContent = (heading: string, subHeading: string, image: any, textColor?: string) => {
  return (
    <div>
      <ImageGridItemImage src={image.url} />
      <ImageGridItemHeading color={textColor ? textColor : theme.brand.colors.black}>{heading}</ImageGridItemHeading>
      <ImageGridItemSubHeading color={textColor ? textColor : theme.brand.colors.darkGrey}>{subHeading}</ImageGridItemSubHeading>
    </div>
  )
}

const ImageGridItem = ({ heading, subHeading, image, url, textColor } : ImageGridItemProps) => {

  return (
    <div>
      {
        url && <Link to={url}>
        {renderImageGridItemContent(heading, subHeading, image, textColor)}
        </Link>
      }
      {
        !url && renderImageGridItemContent(heading, subHeading, image, textColor)
      }
    </div>
  )
}

export default ImageGridItem
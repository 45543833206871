import React from "react"
import { Grid } from "semantic-ui-react"
import { H2, Paragraph1, Paragraph2 } from "@styles/Global.styles"
import { ImageGridContainerProps, ImageGridItemProps } from "@models/ImageGrid/ImageGrid.models"
import ImageGridItem from "./ImageGridItem"
import theme from "@styles/theme"
import { ImageGridColumn, ImageGridHeading, ImageGridHeadingContainer, ImageGridSubHeading } from "./ImageGrid.styles"

const ImageGridContainer = ({ headingColor, subHeadingColor, textColor, heading, subHeading, imageGridItems } : ImageGridContainerProps) => {

  return (
    <Grid columns={15}>
        <ImageGridHeadingContainer>
            <ImageGridHeading color={headingColor ? headingColor : theme.brand.colors.black}>{heading}</ImageGridHeading>
            {
                subHeading &&
                <ImageGridSubHeading color={subHeadingColor ? subHeadingColor : theme.brand.colors.darkGrey}>{subHeading}</ImageGridSubHeading>
            }
        </ImageGridHeadingContainer>        
        <Grid.Row columns={16} centered>
            {
                imageGridItems.map((imageGridItem: ImageGridItemProps, index) => {
                    return (
                        <ImageGridColumn key={index} mobile={15} tablet={7} computer={7} largeScreen={4}>
                            <ImageGridItem textColor={textColor} {...imageGridItem} />
                        </ImageGridColumn>
                    )
                })
            }
        </Grid.Row>
    </Grid>
  )
}

export default ImageGridContainer